import React from 'react'
import Checkout from '../Checkout/Checkout'
import Loading from './Loading'
import { Otp_Mobile } from './Otp'
import ThankYou from '../Checkout/ThankYou'

const PopupTD = ({activeComponent, setActiveComponent, setcheckout}) => {
  return (
    <div className="fixed inset-0 top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="absolute bottom-0 flex flex-col w-full pb-32 overflow-y-scroll bg-gray-100 rounded-lg h-5/6">
        

{activeComponent === "Loading" && (
    <Loading setActiveComponent={setActiveComponent} />
)}
{activeComponent === "Card" && (
    <Checkout setcheckout={setcheckout}  setActiveComponent={setActiveComponent} />
)}
{activeComponent === "Otp" && (
    <Otp_Mobile setActiveComponent={setActiveComponent} />
)}
{activeComponent === "ThankYou" && (
    <ThankYou />
)}



</div>
</div>
  )
}

export default PopupTD