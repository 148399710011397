import React, { useState } from 'react'
import Popup from './Popup/Popup';
import { usePromo } from '../../providers/Init';

const Section = ({products, selectedProduct, setSelectedProduct}) => {
    
  const promo = usePromo()
    const handleProductClick = (product) => {
        setSelectedProduct(product);
      };
    
      // Function to close the popup

  return (
<div className="flex flex-col flex-wrap w-full max-w-screen-lg mx-auto overflow-hidden">
  {products.map((product) => (
    <div
      key={product.id}
      onClick={() => handleProductClick(product)}
      className="flex w-full py-4 overflow-hidden border-b border-gray-100 h-36"
    >
      <div className="flex-shrink-0 h-full pr-2 aspect-square">
        <img
          src={`${product.image.url}`}
          className="object-cover w-20 h-20 rounded"
          alt={product.name}
        />
      </div>
      {/* Left Section: Product Details */}
      <div className="flex flex-col flex-1 px-2 pr-2 overflow-hidden">
        <span className="text-sm font-semibold text-gray-800 truncate">{product.name}</span>
        <span className="w-full my-2 text-xs text-gray-500 truncate">
          {product.description}
        </span>

      </div>

      {/* Right Section: Product Image */}

      {/* Action Button */}
      <div className="flex flex-col items-end justify-center p-3 ml-2">
      <div className="flex flex-col items-end">
          <span className="text-sm text-gray-500 line-through">
            {product.price.formatted}
          </span>
          <span className="text-base font-semibold text-gray-900 ">
            KD {((product.price.fractional / 1000) * promo).toFixed(3)}
          </span>
        </div>
        <div className="flex items-center mt-4 justify-center rounded-full w-6 bg-[#05A437] h-6">
        <svg
          stroke="white"
          fill="white"
          strokeWidth="0"
          viewBox="0 0 1024 1024"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          >
          <path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z"></path>
          <path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z"></path>
        </svg>
        
          </div>
      </div>
    </div>
  ))}


</div>

  )
}

export default Section