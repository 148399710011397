import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CartProvider } from 'react-use-cart';
import { PromoProvider } from './providers/Init';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <PromoProvider>

        <CartProvider>

          <App />
        </CartProvider>
  </PromoProvider>
);


