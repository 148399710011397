import React from 'react';

const ThankYou = () => {
  const getTimeRange = () => {
    const now = new Date();
    const start = new Date(now.getTime() + 15 * 60000); // Current time + 15 minutes
    const end = new Date(now.getTime() + 25 * 60000); // Current time + 25 minutes
    
    const formatTime = (date) => {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    };

    return `${formatTime(start)}–${formatTime(end)}`;
  };

  return (
    <div className="w-full">
    <div className="w-full h-40 bg-prim_blue"> </div>
    <div className="relative max-w-sm p-4 mx-4 bg-white border border-gray-200 rounded-lg shadow-md -top-24">
      <div className="flex items-center justify-between mb-2 text-sm font-semibold text-gray-500">
        <span>Order status</span>
        <span className="font-bold text-gray-900">{getTimeRange()}</span>
      </div>
      <div className="relative w-full h-2 mb-4 overflow-hidden bg-gray-200 rounded-full">
        <div className="absolute top-0 left-0 h-full bg-[#FF5A00]" style={{ width: '20%' }}></div>
      </div>
      <div className="flex items-center justify-between">
        <span className="font-medium text-gray-900">Confirming your order with Ananas اناناس</span>
        <button className="text-xl font-bold text-[#FF5A00]">&#9660;</button>
      </div>
      <div className="mt-2">
        <button className="text-sm font-semibold text-[#FF5A00]">Cancel order</button>
      </div>
    </div>
    </div>
  );
};

export default ThankYou;
