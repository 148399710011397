import React from "react";

const Info = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-sm">
      {/* Restaurant Name */}
      <div className="mb-4 text-lg font-bold">Ananas</div>
      
      {/* Info List */}
      <div className="space-y-3">
        {/* Minimum Order Amount */}
        <div className="flex justify-between">
          <span className="text-gray-600">Minimum Order Amount</span>
          <span className="font-semibold">KD 0.000</span>
        </div>

        {/* Delivery Time */}
        <div className="flex justify-between">
          <span className="text-gray-600">Delivery Time</span>
          <span className="font-semibold">17 mins</span>
        </div>

        {/* Pre-Order */}
        <div className="flex justify-between">
          <span className="text-gray-600">Pre-Order</span>
          <span className="font-semibold">No</span>
        </div>

        {/* Payment Methods */}
        {/* <div className="flex items-center justify-between">
          <span className="text-gray-600">Payment</span>
          <div className="flex space-x-2">
            <img
              src="https://via.placeholder.com/32x20?text=Visa"
              alt="Visa"
              className="w-8 h-5"
            />
            <img
              src="https://via.placeholder.com/32x20?text=MC"
              alt="MasterCard"
              className="w-8 h-5"
            />
            <img
              src="https://via.placeholder.com/32x20?text=KNET"
              alt="KNET"
              className="w-8 h-5"
            />
          </div>
        </div> */}

        {/* Rating */}
        <div className="flex items-center justify-between">
          <span className="text-gray-600">Rating</span>
          <span className="flex items-center">
            <span className="text-lg text-yellow-500">😄</span>
            <span className="ml-2 font-semibold">Amazing</span>
          </span>
        </div>

        {/* Cuisines */}
        <div className="flex justify-between">
          <span className="text-gray-600">Cuisines</span>
          <span className="font-semibold">Healthy, Ice Cream, Coffee</span>
        </div>
      </div>
    </div>
  );
};

export default Info;
