import React, { useEffect, useState } from 'react'
import products_data from "../../../data/mcdonalds_new.json" // Adjust the path if necessary
import CustomSelect from './CustomSelect'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { useCart } from "react-use-cart"
import { usePromo } from '../../../providers/Init';
const Popup = ({selectedProduct, closePopup}) => {
  const promo = usePromo()
  const {
    addItem,
    removeItem,
    updateItemQuantity,
    items,
    isEmpty,
    totalItems,
    cartTotal,
    emptyCart,
  } = useCart()

  const [amount, setAmount] = useState(1);
  const [eddited_price, set_eddited_price] = useState(selectedProduct.price.fractional/1000)
  const [topups, set_topups] = useState(0)
  

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [totalPrice, setTotalPrice] = useState(0);

  const [submitBtn, setSubmitBtn] = useState(<span>Add for KD {((eddited_price * amount + (totalPrice/1000))*promo).toFixed(3)}</span>)
  // Calculate total price when selectedOptions changes
  useEffect(() => {
    const total = selectedOptions.reduce(
      (acc, option) => acc + (option?.price?.fractional || 0),
      0
    );
    setTotalPrice(total);
  }, [selectedOptions]);

  useEffect(()=>{
    setSubmitBtn(<span>Add for KD {((eddited_price * amount + (totalPrice/1000))*promo).toFixed(3)}</span>)
  },[amount, totalPrice, eddited_price])


  const increaseAmount = () => {
    setAmount(amount + 1);
  };

  const decreaseAmount = () => {
    if (amount > 1) {
      setAmount(amount - 1);
    }
  };

  const handleAddItem = () => {
    setSubmitBtn(<div className="inputLoader"></div>);
    console.log(amount);
  
    const item = {
      id: selectedProduct.id,
      name: selectedProduct.name,
      price: eddited_price,  // Set price per unit, not total price here
      quantity: amount,
    };
  
    addItem(item, amount); // Pass quantity or amount to addItem
  
    setTimeout(() => {
      closePopup();
      console.log(items);
    }, 2000);
  };
  return (
    <div className="fixed inset-0 top-0 bottom-0 left-0 right-0 z-50 flex items-end justify-center bg-black bg-opacity-50">
    <div className="relative w-full h-[80%] px-2 pb-52 overflow-y-scroll bg-white rounded-lg">
        <div className="fixed bottom-0 left-0 right-0 flex flex-col p-4 bg-white border-t">
        <div className="inline-flex items-center w-full space-x-2 justify-evenly">
        <button
          onClick={decreaseAmount}
          disabled={amount === 1}
          className={`p-1 rounded-full border border-gray-300 ${
            amount === 1 ? 'cursor-not-allowed ' : ' border-prim_blue'
          }`}
        >
          <AiOutlineMinus  color={amount === 1 ? 'gray' :'#FF5A00'}/>
        </button>
        <span className="text-lg">{amount}</span>
        <button
          onClick={increaseAmount}
          className="p-1 border rounded-full border-prim_blue"
        >
          <AiOutlinePlus color={'#FF5A00'} />
        </button>
      </div> 
          <button onClick={handleAddItem} className='w-full p-3 mt-4 text-lg font-semibold text-white rounded-lg bg-prim_blue'>{submitBtn}</button>
        </div>
        <div onClick={closePopup} className="fixed flex items-center justify-center w-10 h-10 bg-white rounded-full shadow top-3 right-3">
        <svg stroke="#FF5A00" fill="#FF5A00" stroke-width="0" viewBox="0 0 1024 1024" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>
        </div>
        <div className="w-full">
            <img src={`${selectedProduct.image.url}`} className='object-cover w-full ' alt="" />
        </div>
      <h3 className="mt-4 mb-2 text-2xl font-semibold">{selectedProduct.name}</h3>
      <h3 className="mb-4 text-sm text-neutral-500">{selectedProduct.description}</h3>
      <ul className="mb-4 ">
      {selectedProduct.modifierGroupIds && selectedProduct.modifierGroupIds.length > 0 && (
  <ul>
    {selectedProduct.modifierGroupIds && selectedProduct.modifierGroupIds.length > 0 && (
  <ul>
    {selectedProduct.modifierGroupIds.map((modifierGroupId, index) => {
      // Find the corresponding modifier group in modifierGroups
      const correspondingModifierGroup = products_data.modifierGroups.find(group => group.id === modifierGroupId);

      // If a corresponding group is found, render its name or other properties
      return correspondingModifierGroup ? (
        <li key={index} className="mb-6">
            <div className="flex flex-col">
                <span className='my-2 text-lg font-semibold'>{correspondingModifierGroup.name}</span>
                <CustomSelect
                  options={correspondingModifierGroup.modifierOptions}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  groupIndex={index} 
                />

            </div>
           {/* You can display any other property here */}
        </li>
      ) : null; // Return null if no group is found to avoid rendering empty space
    })}
  </ul>
)}

  </ul>
)}

      </ul>

    </div>
  </div>
  )
}

export default Popup