import React, { useState, useEffect } from 'react';
import CountdownTimer from './CountDownTimer';
import { usePromo } from './providers/Init';


const PromoOverlay = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isFading, setIsFading] = useState(false);

  const promo = usePromo()
  // Check local storage to determine if the overlay should show
  useEffect(() => {
    const isPromoDismissed = localStorage.getItem('promoDismissed');
    if (!isPromoDismissed) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    setIsFading(true); // Start fade-out effect
    setTimeout(() => {
      setIsVisible(false); // Remove overlay after animation
      localStorage.setItem('promoDismissed', 'true'); // Save dismissal in local storage
    }, 500); // Match duration with CSS transition
  };

  return (
    isVisible && (
      <div
        className={`fixed flex z-50 items-center justify-center w-full h-screen p-8 bg-black backdrop-blur-md bg-opacity-60 transition-opacity duration-500 ${
          isFading ? 'opacity-0' : 'opacity-100'
        }`}
      >
        <div className="flex flex-col items-center w-full h-auto max-w-md overflow-hidden bg-white shadow-lg rounded-xl">
          <img src="/assets/offers.png" className="object-cover w-full h-full" alt="Promo Offer" />
            <div className="flex justify-center w-[700px] overflow-auto bg-yellow-300 -rotate-6 shadow mt-4">
              <span className='text-center w-[700px] font-black text-xl'>BLACK FRIDAY SALE BLACK FRIDAY SALE BLACK FRIDAY SALE </span>
            </div>
            <div className="flex justify-center w-[700px] overflow-auto bg-yellow-300 relative -top-4 shadow rotate-3">
              <span className='text-center w-[700px] font-black text-xl'>BLACK FRIDAY    BLACK FRIDAY    BLACK FRIDAY SALE </span>
            </div>
          <div className="px-6 pb-6 text-center">
            {/* <h2 className="text-3xl font-bold text-prim_blue">🎉 Happy Hours! 🎉</h2> */}
            <p className="mb-4 text-xl text-gray-700 ">
              

  Enjoy <span className="font-bold text-yellow-500">-{100- (promo*100)}% off</span> on all your purchases at 
  <span className="font-bold text-prim_blue"> Ananas اناناس</span>!

            </p>
            <p className="mt-2 text-xs text-gray-500">Hurry, the offer ends when the timer runs out! ⏳</p>
            <CountdownTimer />
            <button
              className="w-full px-6 py-3 mt-6 text-lg font-semibold text-white rounded-lg bg-prim_blue hover:bg-yellow-600"
              onClick={handleClose}
            >
              Shop Now
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default PromoOverlay;
