import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="p-6 text-white bg-black">
      {/* Navigation Links */}
      <div className="space-y-4">
        <div className="flex justify-between pb-2 border-b border-gray-700">
          <span>RESTAURANTS</span>
          <span>+</span>
        </div>
        <div className="flex justify-between pb-2 border-b border-gray-700">
          <span>POPULAR CUISINES</span>
          <span>+</span>
        </div>
        <div className="flex justify-between pb-2 border-b border-gray-700">
          <span>POPULAR AREAS</span>
          <span>+</span>
        </div>
      </div>

      {/* Social Media Links */}
      <div className="mt-6">
        <h4 className="mb-4 text-sm text-center">FOLLOW US ON</h4>
        <div className="flex justify-center space-x-4">
          <FaFacebook className="text-2xl cursor-pointer hover:text-gray-400" />
          <FaTwitter className="text-2xl cursor-pointer hover:text-gray-400" />
          <FaInstagram className="text-2xl cursor-pointer hover:text-gray-400" />
          <FaLinkedin className="text-2xl cursor-pointer hover:text-gray-400" />
          <FaYoutube className="text-2xl cursor-pointer hover:text-gray-400" />
        </div>
      </div>

      {/* Additional Links */}
      <div className="mt-6 space-y-2 text-sm">
        <div className="flex justify-center space-x-6">
          <a href="#careers" className="hover:underline">
            Careers
          </a>
          <a href="#terms" className="hover:underline">
            Terms and Conditions
          </a>
          <a href="#faq" className="hover:underline">
            FAQ
          </a>
          <a href="#privacy" className="hover:underline">
            Privacy Policy
          </a>
        </div>
        <div className="flex justify-center space-x-6">
          <a href="#contact" className="hover:underline">
            Contact Us
          </a>
          <a href="#sitemap" className="hover:underline">
            Sitemap
          </a>
        </div>
      </div>

      {/* Footer Note */}
      <div className="mt-6 text-xs text-center text-gray-400">
        <p>For any support or help you can contact us via our Live Chat</p>
        <p>©2024 Talabat.com</p>
      </div>
    </footer>
  );
};

export default Footer;
