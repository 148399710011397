import React, { createContext, useContext, useState, useEffect } from 'react';

import ReactPixel from 'react-facebook-pixel';
import useHistoryParams from './useHistoryParam';


const PromoContext = createContext();

export const usePromo = () => useContext(PromoContext);

export const PromoProvider = ({ children }) => {
  const { params, updateParams } = useHistoryParams();
  const [promo, setPromo] = useState(0.5);

  useEffect(() => {
    const pixelParam = params.get('p');
    const clickParam = params.get('click_id');
    const promoAmount = params.get('promo'); // Retrieve 'promo' parameter

    if (pixelParam) {
      ReactPixel.init(pixelParam);
      console.log('Facebook Pixel initialized with:', pixelParam);
    }
    if (clickParam) {
      localStorage.setItem('clickParam', clickParam)
    }

    if (promoAmount) {
      setPromo((100-promoAmount)/100);
      console.log('Promo amount set to:', (100-promoAmount)/100);
    }


    // Remove params from URL if they exist
    if (pixelParam ) {
      updateParams({ p: null, promo: null});
    }
  }, [params, updateParams]);

  return (
    <PromoContext.Provider value={promo}>
      {children}
    </PromoContext.Provider>
  );
};
