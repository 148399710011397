import React from "react";

const reviewsData = [
  {
    rating: "🙂 Good",
    name: "Moj M***",
    date: "26 November 2024",
    comment: "تم طلب قهوة كوكو مع حلاوة عادية الا انها مرررة جدا ولم الالتزام بالطلب..وطلب بينك باستا لكن بااااردة",
  },
  {
    rating: "🙂 Good",
    name: "Nabeela M***",
    date: "26 November 2024",
    comment: "The cookie was cold",
  },
  {
    rating: "😄 Amazing",
    name: "Yousef M***",
    date: "25 November 2024",
    comment: "جميل",
  },
  {
    rating: "😄 Amazing",
    name: "Noor H***",
    date: "25 November 2024",
    comment: "Everything was amazing",
  },
  {
    rating: "🙂 Good",
    name: "R M***",
    date: "24 November 2024",
    comment: "القهوة بلاسكر ونوعاً مررر",
  },
];

const Reviews = () => {
  return (
    <div className="">
      {/* Header */}
      <h2 className="mb-4 text-lg font-bold">(96) Reviews</h2>
      {/* Rating summary */}
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="text-sm">
          <span className="font-bold">Very good</span>
          <p>Order Packaging</p>
        </div>
        <div className="text-sm">
          <span className="font-bold">Very good</span>
          <p>Value for money</p>
        </div>
        <div className="text-sm">
          <span className="font-bold">Very good</span>
          <p>Delivery time</p>
        </div>
        <div className="text-sm">
          <span className="font-bold">Very good</span>
          <p>Quality of food</p>
        </div>
      </div>
      {/* Reviews List */}
      {reviewsData.map((review, index) => (
        <div
          key={index}
          className="p-4 mb-4 bg-white border rounded-lg shadow-sm"
        >
          <div className="flex justify-between mb-2">
            <span className="font-semibold">{review.rating}</span>
            <span className="text-sm text-gray-500">{review.date}</span>
          </div>
          <div className="mb-2 text-sm text-gray-800">
            <span className="font-semibold">{review.name}</span>
          </div>
          <p className="text-gray-600">{review.comment}</p>
        </div>
      ))}
    </div>
  );
};

export default Reviews;
